/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Ali Raza's Portfolio",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Ali Raza Portfolio",
    type: "website",
    url: "http://aliabbasi.dev/",
  },
};

//Home Page
const greeting = {
  title: "Ali Raza",
  logo_name: "AliRaza",
  nickname: "Ali",
  subTitle:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  resumeLink:
    "https://drive.google.com/file/d/1vdWhJAkKlcv5qMQz6AdOUpopII-LJFTI/view?usp=sharing",
  portfolio_repository: "https://github.com/aliraza-abbasi",
  githubProfile: "https://github.com/aliraza-abbasi",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/aliraza-abbasi",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/aliraza-abbasi/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  // {
  //   name: "YouTube",
  //   link: "https://youtube.com/c/DevSense19",
  //   fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
  //   backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  // },
  {
    name: "Gmail",
    link: "mailto:mr.aliraza.dev@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "X-Twitter",
    link: "https://twitter.com/AliRazaAbbasi_",
    fontAwesomeIcon: "fa-x-twitter", // Reference https://fontawesome.com/icons/x-twitter?f=brands&s=solid
    backgroundColor: "#000000", // Reference https://simpleicons.org/?q=x
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/mr.aliraza.abbasi/",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/mr.aliraza.abbasi/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Web Scraping & Automation",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Proficient in Python scripting for web scraping, data extraction, and automation tasks",
        "⚡ Experienced in using libraries like pandas for data manipulation and analysis",
        "⚡ Skilled in utilizing Selenium for web scraping and automation of browser interactions",
      ],
      softwareSkills: [
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Scrapy",
          imageSrc: "scrapy-logo.png",
        },
        {
          skillName: "Beautiful Soup",
          imageSrc: "beautifulsoup-logo.png",
        },
        {
          skillName: "Selenium",
          imageSrc: "selenium-logo.svg",
        },
        {
          skillName: "Pandas",
          imageSrc: "pandas-logo.svg",
        },
      ],
    },
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Creating application backend in Django, Flask, Node & Express",
        "⚡ Building responsive website front end using React-Redux",
        "⚡ Developing mobile applications using React Native and solo android apps using Kotlin",
      ],
      softwareSkills: [
        {
          skillName: "Django",
          imageSrc: "django-icon.svg",
        },
        {
          skillName: "Flask",
          imageSrc: "flask-icon.svg",
        },
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "devicon-plain:nodejs-wordmark",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Yarn",
          fontAwesomeClassname: "simple-icons:yarn",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Gatsby",
          fontAwesomeClassname: "simple-icons:gatsby",
          style: {
            color: "#663399",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
        "⚡ Deploying deep learning models on cloud to use on mobile devices",
        "⚡ Setting up streaming jobs from DB to Server or vice-versa on GCP and AWS",
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
    {
      title: "UI/UX Design",
      fileName: "DesignImg",
      skills: [
        "⚡ Designing highly attractive user interface for mobile and web applications",
        "⚡ Customizing logo designs and building logos from scratch",
        "⚡ Creating the flow of application functionalities to optimize user experience",
      ],
      softwareSkills: [
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "Inkscape",
          fontAwesomeClassname: "simple-icons:inkscape",
          style: {
            color: "#000000",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/u/mr-aliraza/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/aliraza_abbasi",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://www.codechef.com/users/aliraza_abbasi",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Islamia University of Bahawalpur",
      subtitle: "B.S. in Computer Science",
      logo_path: "iub_logo.png",
      alt_name: "Islamia University of Bahawalpur",
      duration: "2014 - 2018",
      descriptions: [
        "⚡ Completed a comprehensive computer science curriculum covering core programming concepts, algorithms, and software engineering principles.",
        "⚡ Acquired skills in data structures, database management, web development, and software testing through coursework.",
        "⚡ Demonstrated proficiency in designing and implementing software solutions through project work, enhancing problem-solving abilities and understanding of programming languages and development methodologies.",
      ],
      website_link: "https://www.iub.edu.pk/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Machine Learning",
      subtitle: "- Andrew Ng",
      logo_path: "stanford_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/22MTSSC5WDTM",
      alt_name: "Stanford University",
      color_code: "#8C151599",
    },
    {
      title: "Deep Learning",
      subtitle: "- Andrew Ng",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/H8CPSFXAJD2G",
      alt_name: "deeplearning.ai",
      color_code: "#00000099",
    },
    {
      title: "ML on GCP",
      subtitle: "- GCP Training",
      logo_path: "google_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/EB4VJARK8647",
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
    {
      title: "Data Science",
      subtitle: "- Alex Aklson",
      logo_path: "ibm_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/PLEAPCSJBZT5",
      alt_name: "IBM",
      color_code: "#1F70C199",
    },
    {
      title: "Big Data",
      subtitle: "- Kim Akers",
      logo_path: "microsoft_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/164zKCFOsI4vGqokc-Qj-e_D00kLDHIrG/view",
      alt_name: "Microsoft",
      color_code: "#D83B0199",
    },
    {
      title: "Advanced Data Science",
      subtitle: "- Romeo Kienzler",
      logo_path: "ibm_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/BH2T9BRU87BH",
      alt_name: "IBM",
      color_code: "#1F70C199",
    },
    {
      title: "Advanced ML on GCP",
      subtitle: "- GCP Training",
      logo_path: "google_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/5JZZM7TNQ2AV",
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
    {
      title: "DL on Tensorflow",
      subtitle: "- Laurence Moroney",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/6T4DCUGNK8J8",
      alt_name: "deeplearning.ai",
      color_code: "#00000099",
    },
    {
      title: "Fullstack Development",
      subtitle: "- Jogesh Muppala",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/certificate/NRANJA66Y2YA",
      alt_name: "Coursera",
      color_code: "#2A73CC",
    },
    {
      title: "Kuberenetes on GCP",
      subtitle: "- Qwiklabs",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://google.qwiklabs.com/public_profiles/e4d5a92b-faf6-4679-a70b-a9047c0cd750",
      alt_name: "GCP",
      color_code: "#4285F499",
    },
    {
      title: "Cryptography",
      subtitle: "- Saurabh Mukhopadhyay",
      logo_path: "nptel_logo.png",
      certificate_link:
        "https://drive.google.com/open?id=1z5ExD_QJVdU0slLkp8CBqSF3-C3g-ro_",
      alt_name: "NPTEL",
      color_code: "#FFBB0099",
    },
    {
      title: "Cloud Architecture",
      subtitle: "- Qwiklabs",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://google.qwiklabs.com/public_profiles/5fab4b2d-be6f-408c-8dcb-6d3b58ecb4a2",
      alt_name: "GCP",
      color_code: "#4285F499",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  // subtitle: "Work, Internship and Volunteership",
  description:
    "With over 7 years of professional experience in software development, I have honed my skills in creating robust and scalable solutions. My journey in the field has equipped me with a deep understanding of programming languages, software development methodologies, and the ability to solve complex problems efficiently. I am passionate about leveraging technology to build innovative solutions that make a positive impact.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Sr. Full Stack Engineer (Remote)",
          company: "Squadio",
          company_url: "https://www.linkedin.com/company/joinsquadio/about/",
          logo_path: "squadio_logo.png",
          duration: "Jan 2024 - Present",
          location: "Riyadh, Saudi Arabia",
          description:
            "Responsible for developing and maintaining web applications using Python Django for the backend and React for the frontend. Collaborate with cross-functional teams to deliver high-quality software solutions, ensuring seamless integration of RESTful APIs and implementing best practices for code quality.",
          color: "#000000",
        },
        {
          title: "Principal Software Engineer",
          company: "Tkxel",
          company_url: "https://www.linkedin.com/company/tkxel/about/",
          logo_path: "tkxel_logo.png",
          duration: "Aug 2023 - Jan 2024",
          location: "Lahore, Pakistan",
          description:
            "Led the development of the Kabayan Remit application, enhancing its functionality and performance. Provided technical leadership and mentorship to junior developers, designed and implemented a microservices architecture for scalable and maintainable code, and utilized AWS services for deployment and scaling.",
          color: "#0879bf",
        },
        {
          title: "Principal Software Engineer",
          company: "Arbisoft",
          company_url: "https://www.linkedin.com/company/arbisoft/about/",
          logo_path: "arbisoft_logo.png",
          duration: "Mar 2023 - June 2023",
          location: "Lahore, Pakistan",
          description:
            "Led the MIT xOnline and MIT xPro projects, leveraging OpenEdx. Promoted to Principal Software Engineer after demonstrating technical excellence and leadership. Collaborated with MIT to deliver high-quality educational platforms, designed and implemented scalable backend services, and conducted performance optimization and scalability testing.",
          color: "#9b1578",
        },
        {
          title: "Sr. Software Engineer",
          company: "Arbisoft",
          company_url: "https://www.linkedin.com/company/arbisoft/about/",
          logo_path: "arbisoft_logo.png",
          duration: "May 2021 - Mar 2023",
          location: "Lahore, Pakistan",
          description:
            "Worked on projects focusing on educational platforms. Developed and maintained software solutions using OpenEdx. Implemented new features based on client requirements, conducted code reviews, ensured adherence to coding standards, mentored junior developers, and provided technical guidance.",
          color: "#9b1578",
        },
        {
          title: "Python Developer",
          company: "Algomus Inc.",
          company_url: "https://www.linkedin.com/company/algoai/about/",
          logo_path: "algo_logo.png",
          duration: "Jan 2021 - April 2021",
          location: "Lahore, Pakistan",
          description:
            "Developed Python-based solutions for data analysis and automation. Improved the existing codebase for better performance and maintainability. Implemented data pipelines using pandas and other data processing tools, collaborated with data scientists to integrate machine learning models into production.",
          color: "#fc1f20",
        },
        {
          title: "Python Developer",
          company: "Esasoft",
          company_url: "https://www.linkedin.com/company/esasoft/about/",
          logo_path: "esasoft_logo.png",
          duration: "Aug 2019 - Dec 2020",
          location: "Lahore, Pakistan",
          description:
            "Developed Python-based applications, focusing on web scraping and automation. Worked with technologies like pandas and Selenium for data extraction and manipulation. Built and maintained web crawlers to collect data from various sources, integrated data pipelines to automate data processing tasks.",
          color: "#fc1f20",
        },
        {
          title: "Python Developer",
          company: "Allied Biz Tech",
          company_url: "https://www.linkedin.com/company/alliedbiztech/about/",
          logo_path: "allied_logo.png",
          duration: "Mar 2017 - July 2019",
          location: "Bahawalnagar, Pakistan",
          description:
            "Developed web applications using Django for various sectors including Supply Chain, Retail, Inventory Management, Healthcare, Transportation, and Education. Gained foundational experience in Python development and software engineering practices. Assisted in the development and maintenance of internal and client-facing software solutions, implemented unit tests, participated in code reviews and team meetings.",
          color: "#fc1f20",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Web applications and automation projects and deploy them to cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description: "Some of my published Articles, Blogs and Research.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "neuro-symbolic-sudoku-solver",
      name: "Neuro-Symbolic Sudoku Solver",
      createdAt: "2023-07-02T00:00:00Z",
      description: "Paper published in KDD KiML 2023",
      url: "https://arxiv.org/abs/2307.00653",
    },
    {
      id: "mdp-diffusion",
      name: "MDP-Diffusion",
      createdAt: "2023-09-19T00:00:00Z",
      description: "Blog published in Paperspace",
      url: "https://blog.paperspace.com/mdp-diffusion/",
    },
    {
      id: "consistency-models",
      name: "Consistency Models",
      createdAt: "2023-10-12T00:00:00Z",
      description: "Blog published in Paperspace",
      url: "https://blog.paperspace.com/consistency-models/",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "aliraza_abbasi.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with Python, React, Android, Cloud and OpenSource Development.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I like to document some of my experiences in professional career journey as well as some technical knowledge sharing.",
    link: "https://blogs.aliabbasi.dev/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Adan Valley, Bahawalnagar, Punjab, Pakistan 62300",
    locality: "Bahawalnagar",
    country: "Pakistan",
    region: "Punjab",
    postalCode: "62300",
    streetAddress: "Adan Valley",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://maps.app.goo.gl/4mAE8KiVdMamp4HE6",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
